
import { renderData } from "@libraryHelpers/dataComponents.js";
import { licenseItems, licenseStates } from "@libraryHelpers/licenses.js";

export default {
	name: "CoreBlockFooterDisclosures",
	props: {
		site: {
			type: Object,
			required: true,
		},
		isMobile: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			clonedSite: null,
		};
	},
	computed: {
		user() {
			return this.site.user || {};
		},
		isRegisteredRep() {
			return this.user.ddcUserData?.regRepInd === "Y";
		},
		businessLocationState() {
			return this.user.ddcUserData?.discBusLocAddrStateCode;
		},
		disclosures() {
			const disclosures = this.site.footer?.disclosures || [];
			return disclosures.map((item) => renderData(item, this.clonedSite));
		},
		showDisclosures() {
			return !!this.site.footer?.showDisclosures;
		},
		accordionSettings() {
			return {
				type: "accordion",
				variants: {},
				accordionText: "Disclosure",
				hasAccordion: true,
				defaultOpen: true,
				blocks: [
					...this.disclosures.map((disclosure) => {
						return {
							type: "rich-text",
							variants: {
								size: "disclosure",
							},
							richText: disclosure,
						};
					}),
				],
				locked: null,
			};
		},
		sellingInsuranceLicenses() {
			return this.licenses({
				licenseLobCode: "80",
				busLicenseTpCode: "I",
				busEntityCode: "001",
			});
		},
		registeredAgentLicenses() {
			if (!this.isRegisteredRep) {
				return [];
			}
			const licenses = this.licenses({
				licenseLobCode: ["30", "20"],
				busLicenseTpCode: "S",
			});

			if (!licenses.length) {
				const allowedStates = ["CO", "DC", "FL", "LA", "MD"];
				if (allowedStates.includes(this.businessLocationState)) {
					return [
						{
							stateCountyCode: this.businessLocationState,
						},
					];
				}
			}

			return licenses;
		},
		eagleAdvisorLicenses() {
			return this.licenses({ eagleData: "Y" });
		},
		statesSellingInsurance() {
			return this.states(this.sellingInsuranceLicenses);
		},
		sellingInsuranceStateText() {
			return this.stateText(this.sellingInsuranceLicenses);
		},
		statesRegisteredAgent() {
			return this.states(this.registeredAgentLicenses);
		},
		registeredAgentStateText() {
			return this.stateText(this.registeredAgentLicenses);
		},
		statesEagleFinancial() {
			return this.states(this.eagleAdvisorLicenses);
		},
		eagleFinancialStateText() {
			return this.stateText(this.eagleAdvisorLicenses);
		},
	},
	created() {
		this.addDataToSite();
	},
	methods: {
		stateText(states) {
			return states.length > 1 ? "states" : "state";
		},
		licenses(args = {}) {
			return licenseItems(args, this.user.ddcUserData?.ddcLicenseData);
		},
		states(licenses) {
			// create a string of states based on the license data
			const appendableStates = ["CA", "AR"];
			const stateArray = licenses
				.map((license) => {
					const state = license.stateCountyCode;
					// append the license number to certain states
					if (
						this.canShowLicenseNumber(license) &&
						appendableStates.includes(state)
					) {
						return `${state} (${state} Insurance License #${license.licenseIdNumber})`;
					}
					// if the state is a number, use "FL" instead
					if (!isNaN(state)) {
						return "FL";
					}
					return state;
				})
				.join(",")
				.split(",");
			// Sort the array alphabetically
			stateArray.sort();
			// if they are licensed in more than one state, join them with "and" before the last item
			if (stateArray.length > 1) {
				const lastItem = stateArray.pop();
				return `${stateArray.join(", ")}, and ${lastItem}`;
			}

			// if they are licensed in only one state, return the full name of the state
			const singleState = stateArray[0].split(" ")[0].trim();
			const fullState = licenseStates.find((state) => {
				return state.value === singleState;
			});
			if (fullState) {
				const license = licenses.find(
					(l) => l.stateCountyCode === fullState.value
				);
				if (
					this.canShowLicenseNumber(license) &&
					appendableStates.includes(license.stateCountyCode.trim())
				) {
					return `${fullState.text} (${fullState.text} Insurance License #${license.licenseIdNumber})`;
				}
				return fullState.text;
			}
			return "";
		},
		addDataToSite() {
			// here we can add various data points to use in the disclosure via mustache templates
			// needs to be done in created so that the computed data is available in the mustache templates
			// NOTE these variable names are hardcoded into the content library, so if you change them here, you must change them there as well
			this.clonedSite = {
				statesSellingInsurance: this.statesSellingInsurance,
				statesRegisteredAgent: this.statesRegisteredAgent,
				sellingInsuranceStateText: this.sellingInsuranceStateText,
				registeredAgentStateText: this.registeredAgentStateText,
				eagleFinancialStateText: this.eagleFinancialStateText,
				statesEagleFinancial: this.statesEagleFinancial,
				...this.site,
			};
		},
		canShowLicenseNumber(license) {
			return (
				license?.licenseIdNumber &&
				license.licenseLobCode !== "30" && // do not show license number if license is registered rep
				license.licenseLobCode !== "20" &&
				license.busLicenseTpCode !== "S" &&
				license.eagleData !== "Y" // do not show license number if license is eagle financial
			);
		},
	},
};
