// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../Core.Generator/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,700;1,400;1,700&family=Work+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "body{background:var(--_core__body_background);min-height:100vh}*,body{margin:0}*{padding:0}.page .container{margin:0 auto;padding:0;position:relative;width:100%}.page .row{display:flex;flex-flow:row wrap;justify-content:flex-start;margin:0;margin-left:calc(var(--_core__container_gutter)/-2);margin-right:calc(var(--_core__container_gutter)/-2)}.page .row.reverse{flex-direction:row-reverse}.toggle_buttons{border-radius:var(--_core__button_border-radius);display:flex;padding:1px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
