
export default {
	name: "CoreBlockNavItem",
	props: {
		site: {
			type: Object,
			default: () => {},
		},
		parentLinkUrl: {
			type: String,
			default: "",
		},
		navitem: {
			type: Object,
			default: () => {
				return {
					id: "",
					linkText: "",
					isInternalLink: "",
					openInNewTab: false,
					linkUrl: "",
					isFolder: "",
				};
			},
		},
		tier: {
			type: Number,
			default: 1,
		},
		isBuilderMobile: {
			type: Boolean,
			default: false,
		},
		isFooter: {
			type: Boolean,
			default: false,
		},
		isNavOpen: {
			type: Boolean,
			default: false,
		},
		isMobile: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			showDropDown: false,
			autoCollapse: true,
		};
	},
	computed: {
		navItems() {
			return this?.site?.navigation?.links || [];
		},
		fullLinkUrl() {
			if (this.navitem.isInternalLink) {
				return `${
					this.parentLinkUrl !== "/" && this.parentLinkUrl !== ""
						? this.parentLinkUrl
						: ""
				}${this.navitem.linkUrl}`;
			}
			return this.navitem.linkUrl;
		},
		subPages() {
			return this?.navItems.filter((nI) => nI.parent == this.navitem.id);
		},
		target() {
			return this.navitem.openInNewTab ? "_blank" : "_self";
		},
		hasChildren() {
			return this.subPages?.length;
		},
		activeLink() {
			return this.$route?.path.includes(this.navitem?.linkUrl);
		},
	},
	watch: {
		isNavOpen() {
			if (!this.isNavOpen && this.autoCollapse) this.showDropDown = false;
		},
	},
	methods: {
		toggleDropDown() {
			this.showDropDown = !this.showDropDown;
		},
		onClick(event, closeNav = true) {
			if (this.autoCollapse) this.showDropDown = false;
			this.$emit("click", event, closeNav);
		},
	},
};
