
import axios from "axios";
export default {
	data() {
		return {
			SrcPixelImg: "",
			baseURL:
				process.env.NUXT_ENV_API_BASE_URL ||
				"https://localhost:7283/api",
		};
	},
	computed: {
		themes() {
			return this.site?.themes;
		},
		site() {
			return this.$store.state.site;
		},
		customStyles() {
			return this.site?.style || null;
		},
	},
	async mounted() {
		let marketerNumber = this.site.user.ddcUserData?.marketerNo;
		if (marketerNumber) {
			const response = await this.EncryptMarketerNumber(marketerNumber);
			this.GTMPixel(response?.encryptedMarketerNumber);
			this.SrcPixelImg = response?.urlPixel;
		}
	},
	methods: {
		async EncryptMarketerNumber(marketerNumber) {
			try {
				const response = await axios.get(
					`${this.baseURL}/tooling/PixelGateway?marketerNumber=${marketerNumber}`
				);
				return response.data || "";
			} catch (e) {
				return "";
			}
		},
		GTMPixel(EncryptedMarketerNumber) {
			this.$gtm.push({
				marketer_number: EncryptedMarketerNumber,
			});
		},
	},
};
